import React from 'react'

const WhatsAppIcon = ({className}) => {
  return (
    <svg className={className} width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.423378 13C0.423378 6.05686 6.0519 0.428345 12.995 0.428345C19.9382 0.428345 25.5667 6.05686 25.5667 13C25.5667 19.9431 19.9382 25.5716 12.995 25.5716C10.5018 25.5716 8.17816 24.8459 6.22374 23.5941C5.97612 23.4355 5.66552 23.4047 5.39725 23.5251L1.18346 25.4167C0.505825 25.7208 -0.203312 25.0585 0.0540284 24.3617L1.86501 19.4583C1.94965 19.2291 1.92866 18.9752 1.8166 18.7581C0.926179 17.0329 0.423378 15.0752 0.423378 13ZM16.6715 19.2167C15.9738 19.2934 15.2709 19.1276 14.681 18.747C11.7538 16.7718 9.23093 14.2555 7.24816 11.3333C6.86762 10.7435 6.70179 10.0406 6.77854 9.34283C6.85529 8.64508 7.16995 7.99503 7.66961 7.50199L8.09106 7.07096C8.28435 6.882 8.54392 6.77619 8.81423 6.77619C9.08454 6.77619 9.34412 6.882 9.5374 7.07096L11.3573 8.89086C11.5457 9.08252 11.6512 9.34051 11.6512 9.60924C11.6512 9.87797 11.5457 10.136 11.3573 10.3276C11.2614 10.4221 11.1853 10.5347 11.1334 10.6588C11.0814 10.783 11.0546 10.9162 11.0546 11.0508C11.0546 11.1854 11.0814 11.3186 11.1334 11.4428C11.1853 11.5669 11.2614 11.6795 11.3573 11.774L14.2308 14.6475C14.4241 14.8364 14.6837 14.9422 14.954 14.9422C15.2243 14.9422 15.4839 14.8364 15.6772 14.6475C15.8688 14.4591 16.1268 14.3536 16.3955 14.3536C16.6643 14.3536 16.9222 14.4591 17.1139 14.6475L18.9434 16.4578C19.1324 16.6511 19.2382 16.9106 19.2382 17.181C19.2382 17.4513 19.1324 17.7108 18.9434 17.9041L18.5124 18.3256C18.0193 18.8252 17.3693 19.1399 16.6715 19.2167Z" fill="currentColor"/>
    </svg>
  )
}

export default WhatsAppIcon