import React from 'react';

const CartIcon = ({className}) => {
  return (
    <svg className={className} width="22px" height="22px" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9543 11.1429H3.04576C2.78017 11.1406 2.51717 11.1954 2.27454 11.3034C2.0319 11.4115 1.81526 11.5703 1.63924 11.7692C1.46321 11.9681 1.3319 12.2025 1.25417 12.4564C1.17643 12.7104 1.15408 12.9781 1.18862 13.2415L2.54433 23.4557C2.60327 23.9054 2.82466 24.3179 3.16676 24.6155C3.50887 24.9131 3.94804 25.0753 4.40148 25.0715H21.5615C22.0149 25.0753 22.4541 24.9131 22.7962 24.6155C23.1383 24.3179 23.3597 23.9054 23.4186 23.4557L24.7743 13.2415C24.8085 12.9812 24.7871 12.7167 24.7115 12.4653C24.6359 12.2139 24.508 11.9814 24.336 11.7831C24.164 11.5848 23.952 11.4253 23.7138 11.3149C23.4756 11.2045 23.2168 11.1459 22.9543 11.1429Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.35718 15.7858V20.4286" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 15.7858V20.4286" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6428 15.7858V20.4286" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6057 2.85999C18.7182 3.05218 19.7375 3.60245 20.5085 4.42711C21.2795 5.25177 21.7601 6.30569 21.8771 7.42856L22.2857 11.1428" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.71436 11.1429L4.12293 7.42865C4.24758 6.31243 4.73156 5.26695 5.50195 4.44966C6.27235 3.63237 7.28745 3.08753 8.39436 2.89722" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6429 3.25002C17.6429 3.55487 17.5828 3.85674 17.4662 4.13839C17.3495 4.42004 17.1785 4.67595 16.963 4.89152C16.7474 5.10708 16.4915 5.27808 16.2098 5.39474C15.9282 5.5114 15.6263 5.57145 15.3215 5.57145H10.6786C10.0629 5.57145 9.47246 5.32687 9.03711 4.89152C8.60176 4.45616 8.35718 3.8657 8.35718 3.25002C8.35718 2.63434 8.60176 2.04387 9.03711 1.60852C9.47246 1.17317 10.0629 0.928589 10.6786 0.928589H15.3215C15.9371 0.928589 16.5276 1.17317 16.963 1.60852C17.3983 2.04387 17.6429 2.63434 17.6429 3.25002Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CartIcon;